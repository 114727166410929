import { StandaloneMarkup } from 'base/components/Markup';
import useUserAuth from 'lib/hooks/useUserAuth';
import { useUserName } from 'lib/hooks/useUserName';

export const FeminaStandaloneMarkup: typeof StandaloneMarkup = ({ markup, ...props }) => {
  const { isUserLoggedIn } = useUserAuth();
  const { firstName } = useUserName();

  const showMemberMarkup = isUserLoggedIn && markup?.includes('id="femina-member-welcome"');

  const memberMarkup = (
    <div className="flex flex-col items-center gap-1 bg-linen-300 px-6 py-3">
      <h2 className="text-headline-md">Hej {firstName}</h2>
      <div className="text-center text-preamble-sm">
        Vill du uppdatera ditt lösenord?{' '}
        <a
          href="https://login.allermedia.se/aterstall-losenord?return=https%3A%2F%2Fwww.femina.se"
          className="font-bold hover:underline"
        >
          Det kan du göra här
        </a>
      </div>
    </div>
  );

  return showMemberMarkup ? memberMarkup : <StandaloneMarkup {...{ markup }} {...props} />;
};
